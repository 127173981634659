<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2022-10-25 10:33:37
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div class="sld_order_list">
    <MemberTitle :memberTitle="'我的审批'"></MemberTitle>
    <div class="container">
      <h3>{{ L['我的订单'] }}</h3>

      <div class="sld_order_nav">
        <div class="sld_order_nav_con flex_row_start_center pointer">
          <div :class="{ item: true, active: current_state == 1 }" @click="changeState(1)">待审批</div>
          <div :class="{ item: true, active: current_state == 2 }" @click="changeState(2)">已审批</div>
          <!-- <div :class="{item:true,active:current_state==10}" @click="changeState(10)">{{L['待付款']}}</div>
          <div :class="{item:true,active:current_state==20}" @click="changeState(20)">{{L['待发货']}}</div>
          <div :class="{item:true,active:current_state==30}" @click="changeState(30)">{{L['待收货']}}</div>
          <div :class="{item:true,active:current_state==40&&evaluate_state!=1}" @click="changeState(40)">{{L['已完成']}}
          </div>
          <div :class="{item:true,active:current_state==40&&evaluate_state==1}" @click="changeState(40,1)">{{L['待评价']}}
          </div> -->
        </div>

        <div class="search_con1 flex_row_between_center">
          <div class="demo-date-picker5">
            <div class="block">
              <el-date-picker v-model="orderTime" type="daterange" range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" value-format="YYYY-MM-DD" @change="changeTime" />
            </div>
          </div>
          <!-- <el-select v-model="selectTime" placeholder="请选择" @change="changeTime" v-if="selectList.data.length > 0">
            <el-option v-for="item in selectList.data" :key="item" :value="item.id" :label="item.name"></el-option>
          </el-select> -->
          <el-select v-model="installmentName" placeholder="请选择" @change="installmentHandle">
            <el-option v-for="item in installmentList.data" :key="item" :value="item.id" :label="item.name"></el-option>
          </el-select>
          <div class="search_incon flex_row_start_center">
            <el-input class=" search_input" v-model="keyword" placeholder="商品名称" clearable @clear="clear">
            </el-input>
            <div class="search pointer" @click="getOrderList">{{ L['搜索订单'] }}</div>
          </div>
          <div class="search_incon flex_row_start_center">
            <el-input class=" search_input" v-model="memberOrderCode" :placeholder="L['订单号']" clearable @clear="clear">
            </el-input>
            <div class="search pointer" @click="getOrderList">{{ L['搜索订单'] }}</div>
          </div>
          <div class="reset-btn">
            <el-button style="color: white;background-color: #03499e;" @click="resetHandle">重置</el-button>
          </div>
        </div>
      </div>
      <div class="order_title_info flex_row_start_center">
        <div class="time_select">{{ L['订单详情'] }}</div>
        <div class="good_price">{{ L['单价'] }}</div>
        <div class="num">{{ L['数量'] }}</div>
        <!-- <div class="after">{{L['售后维权']}}</div> -->
        <div class="order_price">{{ L['订单金额'] }}</div>
        <div class="state">{{ L['状态'] }}</div>
        <div class="oprate">{{ L['操作'] }}</div>
      </div>
      <div class="order_item" v-for="(orderItem, index) in order_list.data" :key="index">
        <div class="title flex_row_start_center">
          <div>{{ L['下单时间'] }}：{{ orderItem.placeOrderTime }}</div>
          <div class="order_num" @click="goDetail(orderItem.memberOrderCode)">{{ L['订单号'] }}:
            {{ orderItem.memberOrderCode }}</div>
            <div
                class="store_name"
                style="max-width: 160px"
                @click="toStore(orderItem.storeId)"
              >
                <!--<el-row style="width: 120px" >
                  <el-text :truncated="true">{{ orderItem.seller }}</el-text>
                </el-row>-->
                {{ orderItem.seller }}
              </div>
              <!-- <div class="store_service" @click="toKefu(orderItem)">
                <img src="@/assets/goods/server.png" alt="" />
              </div> -->
              <div class="orderPayTyp_eName">
                {{ orderItem.orderPayTypeName }}
              </div>
              <!--<div class="orderPayTyp_eName" v-if="orderItem.paymentWayName">
                {{ orderItem.paymentWayName }}
              </div>-->
          <!--<div class="store_name" @click="toStore(orderItem.storeId)">{{ orderItem.memberName }}</div>-->
          <!-- <div class="store_service" @click="toKefu(orderItem)"><img src="@/assets/goods/server.png" alt=""></div> -->
        </div>
        <div class="good_info_con flex_row_start_center">
          <div class="good_info flex_column_center_start">
            <div class="item flex_row_start_center" v-for="(goodItem, index) in orderItem.goodsDetails" :key="index">
              <div class="good flex_row_start_center">
                <div class="left">
                  <div class="virtual_tag" v-if="orderItem.isVirtualGoods == 2">虚拟</div>
                  <coverImage :src="goodItem.goodsImage" @click="goGoodsDetail(goodItem.skuCode)" width="100"
                    height="100"></coverImage>
                </div>

                <div class="right">
                  <div class="good_name overflow_ellipsis_two" @click="goGoodsDetail(goodItem.skuCode)">
                    {{ goodItem.goodsNameJoin }}</div>
                  <div class="spec overflow_ellipsis">{{ goodItem.boxSpec }}</div>
                </div>
              </div>
              <div class="good_price">￥{{ goodItem.goodsPrice }}</div>
              <div class="num">*{{ goodItem.goodsNum }}</div>
            </div>
          </div>
          <!-- <div class="after flex_row_center_center">--</div> -->
          <div class="order_price flex_row_center_center">￥{{ orderItem.orderSumAmount }}</div>
          <div class="state flex_column_center_center">
            <span class="state_value">{{ formatOrderState(orderItem.orderState) }}</span>
            <p style="margin-top: 9px;">申请人：{{ orderItem.memberName ? orderItem.memberName : '--' }}</p>
          </div>
          <div class="oprate flex_column_center_center">
            <span class="detail" @click="goDetail(orderItem.memberOrderCode)">{{ L['订单详情'] }}</span>

            <!-- <span class="detail" @click="goDetails(orderItem.orderSn)">审批</span> -->
            <!-- 待付款、待发货订单可以修改地址 -->
            <!-- <div v-if="(orderItem.orderState == 10||orderItem.orderState == 20)&&orderItem.isVirtualGoods==1"
              class="action-btn flex_row_center_center" @click="editAddress(orderItem.orderSn)">{{L['修改地址']}}</div> -->
            <!-- 待收货、已完成订单可以查看物流 -->
            <!-- <div v-if="(orderItem.orderState == 30||orderItem.orderState == 40)&&orderItem.isVirtualGoods==1"
              class="action-btn flex_row_center_center"
              @click="lookLogistics(orderItem.orderSn,orderItem.productLeadLittle)">{{L['查看物流']}}</div> -->
            <!-- 待收货订单可以确认收货 -->
            <!-- <div v-if="orderItem.orderState == 30" class="action-btn recom flex_row_center_center"
              @click="confirmReceipt(orderItem.orderSn)">{{L['确认收货']}}</div> -->
            <!-- 待评价订单可以评价 -->
            <!-- <div v-if="orderItem.orderState == 40 && orderItem.evaluateState!=3"
              class="action-btn recom flex_row_center_center" @click="remainEvaluated(orderItem.orderSn)">{{L['评价']}}
            </div> -->
            <!-- 待付款订单可以取消订单 -->
            <!-- <div v-if="orderItem.orderState == 10" class="action-btn flex_row_center_center"
              @click="showSelectReasonDialog(orderItem)">{{L['取消订单']}}</div> -->
            <!-- 待付款订单可以立即支付 -->
            <!-- 查看订单付款比例 -->
            <div v-if="orderItem.parentSn == orderItem.memberOrderCode && orderItem.isStages"
              class="action-btn recom flex_row_center_center" @click="handleInstallment(orderItem.memberOrderCode)">
              查看付款比例</div>
            <div
              v-if="(orderItem.orderState == 10 && !((orderItem.orderType == 105 || orderItem.orderType == 103) && orderItem.orderSubState == 102 && orderItem.depositRemainTime > 0) && memberInfo.data.isLookWallet == 1 && memberInfo.data.hasAccount == 2) && !orderItem.isPayment"
              class="action-btn recom flex_row_center_center" @click="goPay(orderItem.paymentNumber)">{{ L['立即支付'] }}
            </div>
            <!-- 分期付款订单可以分期支付 -->
            <div v-if="orderItem.isPayment" class="action-btn recom flex_row_center_center"
              @click="goPay(orderItem.paymentNumber)">{{ fliter(orderItem.paymentNum) }}</div>
            <!-- 已取消、全部评价完成订单可以删除订单 -->
            <!-- <div v-if="orderItem.orderState==0||(orderItem.orderState==40&&orderItem.evaluateState==3)"
              class="action-btn flex_row_center_center" @click="delOrder(orderItem.orderSn)">{{L['删除订单']}}</div> -->
            <span class="detail" @click="approval_sub(1, orderItem)"
              v-if="orderItem.orderState == 1 && current_state != 2">审核通过</span>
            <span class="detail" @click="approval_sub(2, orderItem)"
              v-if="orderItem.orderState == 1 && current_state != 2">审核拒绝</span>
            <span class="detail" @click="DownloadSalesList(orderItem)"
              v-if="orderItem.orderBelongs !== 2">{{ '下载收货清单' }}</span>
          </div>
        </div>
      </div>
      <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
        :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
        :hide-on-single-page="true" class="flex_row_end_center"></el-pagination>
      <SldCommonEmpty v-if="order_list.data.length == 0" :tip="L['暂无订单～']" totalWidth="934px" />
    </div>
    <!-- 取消理由弹窗 start -->
    <el-dialog :title="L['取消订单理由']" v-model="cancel_order" customClass="select_reason_width"
      :before-close="current_reason_id = ''" lock-sroll="false">
      <div class="cancel_list_con">
        <div v-for="(reasonItem, index) in cancel_list.data" :key="index"
          :class="{ reason_item: true, flex_row_between_center: true, active: current_reason_id == reasonItem.reasonId }"
          @click="selectReason(reasonItem.reasonId)">
          <span class="reason_text">{{ reasonItem.content }}</span>
          <img :src="current_reason_id == reasonItem.reasonId ? selected : not_select" alt />
        </div>
      </div>
      <div class="confirm_cancel_btn" @click="confirmCancel()">{{ L['确定'] }}</div>
    </el-dialog>
    <!--  取消地址理由弹窗 end  -->
    <!-- 查看物流弹窗 start -->
    <el-dialog :title="L['物流信息']" v-model="show_logistics" customClass="select_reason_width" :before-close="handleClose"
      lock-sroll="false">
      <div class="logistics_info">
        <p>{{ logistics_info.data.type == 1 ? L['联系电话'] : L['承运人'] }}：{{ logistics_info.data.expressName }}</p>
        <p>{{ logistics_info.data.type == 1 ? L['联系人'] : L['运单号'] }}：{{ logistics_info.data.expressNumber }}</p>
      </div>
      <div class="logistics_list_con">
        <el-timeline>
          <el-timeline-item v-for="(logistics, index) in logistics_info.data.routeList" :key="index"
            :timestamp="logistics.acceptStation">{{ logistics.acceptTime }}</el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <!--  查看物流弹窗 end  -->
    <!-- 查看分期付款弹窗 start -->
    <el-dialog title="分期付款信息" v-model="show_installment" customClass="select_reason_width" :lock-scroll="false">
      <div class="ment-box">
        <div class="item0">
          <div class="title">付款次数</div>
          <div class="item0-num">{{ installmentObj.data.paymentNum }} 次</div>
        </div>
        <div class="item1" v-for="(item) in installmentObj.data.installmentStagesList" :key="item.id">
          <div class="lt">
            <div class="title">付款状态</div>
            <div v-if="item.paymentStatus == 10">待付款</div>
            <div v-if="item.paymentStatus == 20">待发货</div>
            <div v-if="item.paymentStatus == 30">待收货</div>
            <div v-if="item.paymentStatus == 40">已完成</div>
            <div v-if="item.paymentStatus == 0">质保时间<div>{{ item.guaranteeTime }}</div>
            </div>
            <div>
              <span>付款状态：</span>
              <span>{{ item.verdict == 1 ? '已付款' : '未付款' }}</span>
            </div>
          </div>
          <div class="md">
            <div>付款时间</div>
            <div>{{ item.paymentTime }}</div>
            <div v-if="item.dealId">交易流水号：{{ item.dealId }}</div>
          </div>
          <div class="rt">
            <div>付款比例（百分比）</div>
            <div>{{ item.ratio }}%</div>
            <div>付款金额：￥{{ item.installmentMoney }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--  查看分期付款弹窗 end  -->
    <!-- 地址选择弹窗 start -->
    <el-dialog :title="L['更换地址']" v-model="show_select_address" customClass="select_address_width"
      :before-close="handleClose" lock-sroll="false">
      <div class="out_stock_dialog address_con">
        <div v-for="(item, index) in address_list.data" :key="index" @click="selectAddress(index)"
          :class="{ address_item: true, flex_column_start_start: true, select: current_address_index == index }">
          <span>{{ L['收货人'] }}：{{ item.memberName }}</span>
          <span>{{ L['联系方式'] }}：{{ item.telMobile }}</span>
          <span class="address_text">
            <div>{{ L['收货地址'] }}：</div>
            <div>{{ item.addressAll }} {{ item.detailAddress }}</div>
          </span>
          <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
        </div>
      </div>
      <div class="btn_con flex_row_center_center">
        <div class="confirm_cancel_btn" @click="confirmChangeAddress()">{{ L['提交修改'] }}</div>
      </div>
    </el-dialog>
    <!-- 地址选择弹窗 end -->
    <!-- 审批选择弹窗 start-->
    <el-dialog v-model="diaflag" title="审批" width="560px" class="dialogs" @close=" reason = ''">
      <div class="aftersales_box">
        <div class="approval_box">
          <p class="approval_tit">审批：</p>
          <div class="approval_con">
            <div class="approval_con_1" @click="approval_info(1)" :class="{ 'approval_con_one': approval_flag }">通过</div>
            <div class="approval_con_2" @click="approval_info(2)" :class="{ 'approval_con_one': !approval_flag }">拒绝</div>
          </div>
        </div>
        <div class="approval_box" style="margin-top: 20px;" v-if="!approval_flag">
          <p class="approval_tit">拒绝原因：</p>
          <textarea name="" id="" placeholder="请输入拒绝原因" v-model="reason" maxlength="100"></textarea>
        </div>
      </div>
      <template #footer>
        <div style="width: 78px;"></div>
        <span class="dialog-footer dia_btn">
          <el-button type="primary" @click="diasumber"
            style="background-color: #0e3177;border-color: #0e3177;">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 审批选择弹窗 end-->
  </div>
</template>
<script>
  import { reactive, getCurrentInstance, ref, onMounted,computed } from "vue";
  import {
    ElInput,
    ElMessage,
    ElMessageBox,
    ElTimeline,
    ElTimelineItem
  } from "element-plus";
  import { platform } from '@/utils/config';
  import { useRouter, useRoute } from "vue-router";
  import { useStore } from 'vuex'
  import { apiUrl } from "../../../utils/config";
    import axios from 'axios'
  import SldCommonEmpty from '../../../components/SldCommonEmpty';
  import MemberTitle from '../../../components/MemberTitle';
  export default {
    name: "order-list",
    components: {
      ElInput,
      ElTimeline,
      ElTimelineItem,
      SldCommonEmpty,
      MemberTitle
    },
    setup() {
      // const balance = require("../../assets/buy/balance.png");
      const store = useStore()
      const router = useRouter();
      const route = useRoute();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const current_state = ref(1); //当前列表订单状态
      const evaluate_state = ref(0); //是否是待评价
      const order_list = reactive({ data: [] });
      const orderSn = ref("");//订单id
      const taskId = ref("");//流程任务ID
      const cancel_order = ref(false); //是否显示取消订单弹窗
      const not_select = require("../../../assets/order/not_select.png");
      const selected = require("../../../assets/order/selected.png");
      const invoice_selected = require("../../../assets/buy/invoice_selected.png");
      const cancel_list = reactive({ data: [] });
      const current_reason_id = ref(); //记录当前已选择理由id
      const order_sn = ref(); //记录当前操作的订单号
      const parentOrderSn = ref()
      const show_logistics = ref(false);
      const show_installment = ref(false);
      const installmentName =ref('请选择分期付款订单');
      const installmentList = reactive({data: [
        {id:0,name:'分期付款全部订单'},
        {id:1,name:'分期付款未完成订单'},
        {id:2,name:'分期付款已完成订单'}
      ]});
      const orderTime = ref([]);
      const installmentObj =reactive({data :{}});//分期付款信息
      const isInstallment =ref(false);//是否是分期
      const stagesStatus = ref(null);
      const logistics_info = reactive({ data: {} });
      const show_select_address = ref(false); //是否显示选择地址弹窗
      const address_list = reactive({ data: [] }); //地址列表
      const current_address_index = ref(0); //记录当前选择的地址
      const selectTime = ref('');
      const selectList = reactive({ data: [] });
      const diaflag = ref(false);//显示审批框
      const approval_flag = ref(true);//审批拒绝通过切换
      const reason = ref('');//审批拒绝理由
      const memberInfo = reactive({ data: store.state.memberInfo });
      const pageData = reactive({
        current: 1,
        pageSize: 10,
        total: 0
      });

      // 使用 computed 创建计算属性
      const formatOrderState = computed(() => {
        return (value) => {
          switch (value) {
            case 0:
              return '已取消'
            case 1:
              return '待审批'
            case 10:
              return '待支付'
            case 20:
              return '待发货'
            case 30:
              return '待收货'
            case 40:
              return '已完成'
            case 50:
              return '已关闭'
            default:
              return '未知'
          }
        }
      })

      //显示审批弹框
      const approval_sub = (flag,order)=>{
        orderSn.value = order.memberOrderCode;
        taskId.value = order.taskId;
        if(flag==1){
          approval_flag.value = true
        }else{
          approval_flag.value = false
        }
        diaflag.value = true
      };
      //审批拒绝通过切换
      const approval_info = (flag)=>{
        if(flag==1){
          approval_flag.value = true
        }else{
          approval_flag.value = false
        }
      };
      //审批确定按钮
      const diasumber =()=>{
        let param = {}
        if(approval_flag.value){
          param.approveStatus = 1
        }else{
          if(!reason.value.trim()){
              ElMessage.warning('请输入拒绝原因')
              return false;
          }
          param.approveStatus = 2
          param.cancelReason=reason.value
        }
        // param.orderSn = orderSn.value;
        param.taskId = taskId.value;
        proxy.$post('api/shopp/member/order/audit', param,"json").then(res => {  //原接口：/v3/business/front/orderOperate/auditOrder
          if (res.code == 200) {
            ElMessage('已审批');
            diaflag.value = false;
            orderSn.value = "";
            taskId.value = "";
            reason.value = '';
            getOrderList()
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
        return
      };
      const keyword = ref('');
      const memberOrderCode= ref('');
      //去商品详情页
      const goGoodsDetail = skuCode => {
        proxy.$goGoodsDetail(skuCode);
      };
      //去店铺详情
      const toStore = storeId => {
        if (storeId == 0) {
          return
        }
        proxy.$goStoreDetail(storeId);
      };
      //获取下拉时间
      const getTime = () => {
        proxy
          .$get('v3/business/front/orderInfo/timeList')
          .then(res => {
            if (res.data.length > 0) {
              selectList.data = res.data;
              selectTime.value = selectList.data[0].id;
            }
          })
      };
      //重置
      const resetHandle = () => {
        orderTime.value = [];
        installmentName.value = "请选择分期付款订单";
        memberOrderCode.value = "";
        keyword.value = "";
        stagesStatus.value = "";
        resetPageData();
        getOrderList();
      };
      //切换搜索时间
      const changeTime = (e) => {
        // if(!e) { router.go(0) }
        // isInstallment.value = false;
        resetPageData();
        getOrderList();
      };
      //搜索分期付款订单
      const installmentHandle = (e)=>{
        isInstallment.value = true;
        stagesStatus.value = e;
        getOrderList()
      };
      //重置搜索页面数据
      const resetPageData = () => {
        pageData.current = 1;
        pageData.total = 0;
      };
      //改变订单状态
      const changeState = (orderState, evaluateState) => {
        if (evaluateState) {
          evaluate_state.value = evaluateState;
        } else {
          evaluate_state.value = "";
        }
        current_state.value = orderState;
        pageData.current = 1
        router.push({
          path: "/member/order/approvalList",
          query: {
            orderState,
            evaluateState
          }
        });
        getOrderList()
      };
      //获取订单列表
      const getOrderList = () => {
        var param = {};
        // if (evaluate_state.value == 1) {
        //   param.evaluateState = 1;
        // }
        if (current_state.value) {
          param.orderState = current_state.value;
        }
        // param.orderState = 1
        param.current = pageData.current;
        param.pageSize = pageData.pageSize;
        if (orderSn.value) {
          param.orderSn = orderSn.value;
        }
        param.time = selectTime.value;
        param.orderStatusKeyword = (isInstallment.value || !isInstallment.value&&selectTime.value)?stagesStatus.value:'';
        if(keyword.value){
					param.keyWord = keyword.value;
				}
        if(memberOrderCode.value){
					param.memberOrderCode = memberOrderCode.value;
				}
        if(orderTime.value && orderTime.value.length!=0){
          param.placeOrderTimeStart= orderTime.value[0];
          param.placeOrderTimeEnd= orderTime.value[1];
        }
        param.approveId = memberInfo.data.memberId;
        param.memberName = memberInfo.data.memberName;
        if(current_state.value==1) { // 查询待审核订单
          proxy.$get("api/shopp/member/order/stay/audit/page", param)  //原接口：v3/business/front/orderInfo/auditList
          .then(res => {
            if (res.code == 200) {
              order_list.data = res.data.records;
              pageData.total = res.data.total
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
        }else{  // 查询已审核订单
          proxy.$get("api/shopp/member/order/already/audit/page", param)
          .then(res => {
            if (res.code == 200) {
              order_list.data = res.data.records;
              pageData.total = res.data.total
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
        });
        }
        
      };
      // 获取订单取消理由列表
      const getCancelList = () => {
        proxy
          .$get("v3/system/front/reason/list", {
            type: 104
          })
          .then(res => {
            if (res.state == 200) {
              cancel_list.data = res.data;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //选择理由
      const selectReason = id => {
        current_reason_id.value = id;
      };
      //显示选择理由弹窗
      const showSelectReasonDialog = orderItem => {
        if (!orderItem.isRefundDeposit && (orderItem.orderType == 105 || orderItem.orderType == 103) && orderItem.orderSubState == 102) {
          ElMessageBox.confirm('取消该订单定金不予退还,确定取消?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            cancel_order.value = true;
            parentOrderSn.value = orderItem.parentSn;
          })
        } else {
          cancel_order.value = true;
          parentOrderSn.value = orderItem.parentSn;;
        }
      };
      //显示分期付款比例
      const handleInstallment = (e)=>{
        let param = {};
        param.orderSn = e;
        proxy.$get("v3/business/front/order/getInstallment",param)
          .then(res => {
            if (res.state == 200) {
              installmentObj.data = res.data
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
        });
        show_installment.value = true;
      };
      //显示查看物流信息
      const lookLogistics = orderSn => {
        proxy
          .$get("v3/business/front/logistics/order/getTrace", {
            orderSn
          })
          .then(res => {
            if (res.state == 200) {
              console.log(res.data);
              show_logistics.value = true;
              logistics_info.data = res.data;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //删除订单
      const delOrder = orderSn => {
        ElMessageBox.confirm(L["确认删除该订单?"], L["提示"], {
          confirmButtonText: L["确定"],
          cancelButtonText: L["取消"],
          type: "warning"
        })
          .then(() => {
            proxy
              .$post("v3/business/front/orderOperate/delete", {
                orderSn
              })
              .then(res => {
                if (res.state == 200) {
                  ElMessage.success(L["删除订单成功"]);
                  getOrderList();
                } else {
                  ElMessage(res.msg);
                }
              })
              .catch(() => {
                //异常处理
              });
          })
          .catch(() => { });
      };
      //确认取消订单
      const confirmCancel = () => {
        if (!current_reason_id.value) {
          ElMessage.warning(L["请选择取消理由"]);
          return;
        }
        proxy
          .$post("v3/business/front/orderOperate/cancel", {
            parentSn: parentOrderSn.value,
            reasonId: current_reason_id.value
          })
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(L["取消订单成功"]);
              cancel_order.value = false;
              getOrderList();
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //确认收货
      const confirmReceipt = orderSn => {
        ElMessageBox.confirm(L["确认收货?"], L["提示"], {
          confirmButtonText: L["确定"],
          cancelButtonText: L["取消"],
          type: "warning"
        })
          .then(() => {
            proxy
              .$post("v3/business/front/orderOperate/receive", {
                orderSn
              })
              .then(res => {
                if (res.state == 200) {
                  ElMessage.success(L["确认收货成功"]);
                  getOrderList();
                } else {
                  ElMessage(res.msg);
                }
              })
              .catch(() => {
                //异常处理
              });
          })
          .catch(() => { });
      };
      //分期支付按钮过滤
      const fliter = (e) =>{
        let arr = ['首次付款','二次付款','三次付款','四次付款','五次付款'];
        return arr[e-1]
      };
      //立即支付
      const goPay = orderSn => {
        router.push({
          path: "/buy/pay",
          query: {
            paySn: orderSn,
            payFrom: 1
          }
        });
      };
      //选择地址
      const editAddress = orderSn => {
        show_select_address.value = true;
        order_sn.value = orderSn;
      };
      //获取地址列表
      const getAddressList = () => {
        proxy
          .$get("v3/member/front/memberAddress/list")
          .then(res => {
            if (res.state == 200) {
              address_list.data = res.data.list;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //切换地址
      const selectAddress = index => {
        current_address_index.value = index;
      };
      //确认修改地址
      const confirmChangeAddress = () => {
        proxy
          .$post("v3/business/front/orderOperate/updateAddress", {
            orderSn: order_sn.value,
            addressId: address_list.data[current_address_index.value].addressId
          })
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(L["切换地址成功"]);
              show_select_address.value = false;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //评价订单
      const remainEvaluated = orderSn => {
        router.push({
          path: "/member/order/evaluate",
          query: {
            orderSn: orderSn
          }
        });
      };
      //详情
      const goDetail = orderSn => {
        router.push({
          path: "/member/order/orderReviews",
          query: {
            orderSn: orderSn
          }
        });
      };
      //审核
      const goDetails = orderSn => {
        router.push({
          path: "/member/order/orderReview",
          query: {
            orderSn: orderSn
          }
        });
      };
      //向前翻页
      const handlePrevCilickChange = (e) => {
        pageData.current = e;
        getOrderList();
      };
      //向后翻页
      const handleNextCilickChange = () => {
        pageData.current++;
        getOrderList();
      };
      //页数改变
      const handleCurrentChange = current => {
        pageData.current = current;
        getOrderList();
      };
      onMounted(() => {
        
        if (route.query.orderState) {
          current_state.value = route.query.orderState;
        } else {
          current_state.value = 1;
        }
        if (route.query.evaluateState) {
          evaluate_state.value = route.query.evaluateState;
        } else {
          evaluate_state.value = 1;
        }
        getTime();
        getOrderList();
        getCancelList();
        getAddressList();
      });
      //清空搜索订单
      const clear = () => {
        orderSn.value = ""
        getOrderList()
      }

      const toKefu = (item) => {
        let chatInfo = {
          storeId: item.storeId,
          vendorAvatar: item.storeLogo,
          storeName: item.storeName,
          source: '从订单列表进入'
        }
        store.commit('saveChatBaseInfo', chatInfo)


        let newWin = router.resolve({
          path: '/service',
          query: {
            vid: item.storeId
          }
        })

        window.open(newWin.href, "_blank")
      }
      const DownloadSalesList = (e) => {
      proxy
        .$get("api/shopp/member/order/consignmentSheet", { orderCode: e.memberOrderCode ,flag:platform})
        .then((res) => {
          //原接口：/v3/business/front/contract/getOrderContract
          if (res.code == 200) {
            // download(res.msg);
            window.open(res.data);
            // ElMessage.success("下载成功")
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };

      router.beforeEach((to, from, next) => {
        if (to.query.orderState) {
          current_state.value = to.query.orderState;
        } else {
          current_state.value = "";
        }
        if (to.query.evaluateState) {
          evaluate_state.value = to.query.evaluateState;
        } else {
          evaluate_state.value = "";
        }
        if (to.path == '/member/order/approvalList') {
          getOrderList();
        }
        next();
      });
      return {
        current_state,
        changeState,
        formatOrderState,
        evaluate_state,
        getOrderList,
        order_list,
        orderSn,
        taskId,
        cancel_order,
        not_select,
        selected,
        cancel_list,
        current_reason_id,
        selectReason,
        confirmCancel,
        showSelectReasonDialog,
        delOrder,
        lookLogistics,
        show_logistics,
        logistics_info,
        confirmReceipt,
        goPay,
        installmentObj,
        installmentList,
        isInstallment,
        orderTime,
        installmentName,
        show_installment,
        show_select_address,
        address_list,
        invoice_selected,
        current_address_index,
        resetHandle,
        installmentHandle,
        handleInstallment,
        selectAddress,
        editAddress,
        confirmChangeAddress,
        remainEvaluated,
        goDetail,
        goDetails,
        L,
        goGoodsDetail,
        toStore,
        handlePrevCilickChange,
        handleNextCilickChange,
        handleCurrentChange,
        pageData,
        clear,
        toKefu,
        selectTime,
        selectList,
        getTime,
        changeTime,
        resetPageData,
        keyword,
        memberOrderCode,
        approval_sub,
        diaflag,
        approval_flag,
        approval_info,
        diasumber,
        reason,
        memberInfo,
        fliter,
        DownloadSalesList
      };
    }
  };
</script>
<style lang="scss">
@import "../../../style/ordersDAList.scss";

// <<<<<<< HEAD
.el-pager li.active {
  color: $colorMain;
  cursor: default;
}
// =======
  .el-pager li.active {
    color: $colorMain;
    cursor: default;
    font-size: 16px;
  }
// >>>>>>> d906603456b5cf2e97f10d75593e43eff5d2b526

.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }

    .el-dialog__headerbtn {
      top: auto;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }

  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }

  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .aft_btn:hover {
    background-color: rgba($color: $colorMain, $alpha: 0.2);
    color: $colorMain;
  }

  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }

  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
}
</style>